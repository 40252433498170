import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import useStaticData from 'src/hooks/data/use-static-data'
import configuration from '~/configuration'
import { IPromiseSearchOption } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { formatAddressLocation } from '~/core/utilities/common'
import QueryPublicIndustries from '~/lib/graphql/query-public-industry'
import { IAgencyCompanies } from '../../agency/companies/types'
import QuerySkillsList from '../../candidates/graphql/query-skills-list'
import QueryTalentPoolList from '../../candidates/graphql/query-talent-pool-list'
import { SkillsType } from '../../candidates/types'
import { IDepartment } from '../../settings/departments/types'
import QueryPipelineTemplates from '../../settings/hiring-pipelines/graphql/query-pipeline-template'
import { IPipelineTemplate } from '../../settings/hiring-pipelines/types'
import QueryCompanyListFullOptions from '../../settings/locations/graphql/query-company-list-full-options'
import QueryCompanyLocationOptions from '../../settings/locations/graphql/query-company-location-options'
import QueryTenantLocation from '../../settings/locations/graphql/query-tenant-location'
import { IAgencyLocation, ILocation } from '../../settings/locations/types'
import QueryTenantMembers from '../../settings/members/graphql/query-tenant-members'
import usePromiseOwnerOptions from '../../settings/members/hooks/use-promise-owner-options'
import { IMember } from '../../settings/members/types'
import QueryTagsList from '../../settings/tags/graphql/query-tags-list'
import { ITags } from '../../settings/tags/types'
import { TAG_KIND } from '../../settings/tags/utilities/enum'
import QueryJobCategories from '../graphql/query-job-categories'

const useJobs = () => {
  const { t, i18n } = useTranslation()
  const { clientGraphQL } = useContextGraphQL()
  const jobCurrency = useEnumsData({
    enumType: 'JobCurrency',
    locale: i18n.language
  })
  const jobEducation = useEnumsData({
    enumType: 'JobEducation',
    locale: i18n.language
  })
  const jobEmployment = useEnumsData({
    enumType: 'JobEmployment',
    locale: i18n.language
  })
  const jobLevel = useEnumsData({
    enumType: 'JobJobLevel',
    locale: i18n.language
  })
  const jobRemoteStatus = useEnumsData({
    enumType: 'JobRemoteStatus',
    locale: i18n.language
  })
  const jobTypeOfSalary = useEnumsData({
    enumType: 'JobTypeOfSalary',
    locale: i18n.language
  })

  const languageCollection = useStaticData({
    keyName: 'languages',
    locale: i18n.language
  })
  const languageList = useMemo(
    () =>
      languageCollection.map((item: { language: string; code: string }) => {
        return {
          value: item.code,
          supportingObj: {
            name: item.language
          }
        }
      }),
    [languageCollection]
  )

  const promisePipelineTemplateOptions = (
    params = {} as IPromiseSearchOption
  ) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryPipelineTemplates, { ...params, limit: 100 })
        .toPromise()
        .then((result: IResponseContextResult<IDepartment>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { pipelineTemplatesList } = result.data
          const collection = pipelineTemplatesList?.collection || []
          const metadata = pipelineTemplatesList?.metadata || {}

          const cloneData = collection.map((item: IPipelineTemplate) => {
            return {
              value: item.id,
              supportingObj: {
                name: `${item.name}`,
                shortDescription: item.pipelineStages
                  ?.sort((a, b) => a.index - b.index)
                  .map((s) => s.stageLabel)
                  .join(', ')
              },
              ...item
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  const promiseLocationOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryTenantLocation, params)
        .toPromise()
        .then((result: IResponseContextResult<ILocation>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { tenantLocationsList } = result.data
          const collection = tenantLocationsList?.collection || []
          const metadata = tenantLocationsList?.metadata || {}

          const cloneData = collection.map((item: ILocation) => {
            return {
              value: item.id,
              supportingObj: {
                name: item.name,
                badge: item.headquarter
                  ? `${t('settings:teamMembers:headQuarter')}`
                  : undefined,
                description: [item.address, item.city, item.state]
                  .filter((item) => item)
                  .join(', ')
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  const { promiseMembersOwnerOptions } = usePromiseOwnerOptions()

  const promiseMembersOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryTenantMembers, params)
        .toPromise()
        .then((result: IResponseContextResult<IMember>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { tenantMembers } = result.data
          const collection = tenantMembers?.collection || []
          const metadata = tenantMembers?.metadata || {}

          const cloneData = collection.map((item: IMember) => {
            return {
              value: item.id,
              avatar: item.avatarVariants?.thumb?.url,
              avatarVariants: item.avatarVariants,
              supportingObj: {
                name: item.fullName,
                description: item.email,
                defaultColour: item.defaultColour
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  const promisePublicIndustriesOptions = (
    params = {} as IPromiseSearchOption
  ) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryPublicIndustries, params)
        .toPromise()
        .then(
          (
            result: IResponseContextResult<{
              id: number
              name: string
              status: number
            }>
          ) => {
            if (result.error) {
              catchErrorFromGraphQL({
                error: result.error
              })
              resolve({
                metadata: {
                  totalCount: configuration.defaultAsyncLoadingOptions
                },
                collection: []
              })
            }

            const { publicIndustriesList } = result.data
            const collection = publicIndustriesList?.collection || []
            const metadata = publicIndustriesList?.metadata || {}

            const cloneData = collection.map(
              (item: { id: number; name: string; status: number }) => {
                return {
                  value: item.id,
                  supportingObj: {
                    name: item.name,
                    status: item.status
                  }
                }
              }
            )

            return resolve({ metadata, collection: cloneData })
          }
        )
    })

  const promiseJobCategoriesOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryJobCategories, params)
        .toPromise()
        .then(
          (
            result: IResponseContextResult<{
              id: number
              name: string
              status: number
            }>
          ) => {
            if (result.error) {
              catchErrorFromGraphQL({
                error: result.error
              })
              resolve({
                metadata: {
                  totalCount: configuration.defaultAsyncLoadingOptions
                },
                collection: []
              })
            }

            const { jobCategoriesList } = result.data
            const collection = jobCategoriesList?.collection || []
            const metadata = jobCategoriesList?.metadata || {}

            const cloneData = collection.map(
              (item: { id: number; name: string; status: number }) => {
                return {
                  value: item.id,
                  supportingObj: {
                    name: item.name,
                    status: item.status
                  }
                }
              }
            )

            return resolve({ metadata, collection: cloneData })
          }
        )
    })

  const promiseSkillsOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QuerySkillsList, params)
        .toPromise()
        .then((result: IResponseContextResult<SkillsType>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { skillsList } = result?.data
          const collection = skillsList?.collection || []
          const metadata = skillsList?.metadata || {}

          const cloneData = collection.map((item: SkillsType) => {
            return {
              value: item.name,
              supportingObj: {
                name: item.name
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  const promiseCompanyLocationOptions = (
    params = {} as IPromiseSearchOption & { companyId?: number; jobId?: number }
  ) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryCompanyLocationOptions, params)
        .toPromise()
        .then((result: IResponseContextResult<IAgencyLocation>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { companyLocationsList } = result.data
          const collection = companyLocationsList?.collection || []
          const metadata = companyLocationsList?.metadata || {}

          const cloneData = collection.map((item: IAgencyLocation) => {
            return {
              value: item.id,
              supportingObj: {
                name: formatAddressLocation({
                  location: {
                    address: item.address,
                    city: item.city,
                    state: item.state,
                    country: item.country
                  }
                })
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  const promiseCompanyListOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryCompanyListFullOptions, params)
        .toPromise()
        .then((result: IResponseContextResult<IAgencyCompanies>) => {
          if (result.error) {
            catchErrorFromGraphQL({
              error: result.error
            })
            resolve({
              metadata: {
                totalCount: configuration.defaultAsyncLoadingOptions
              },
              collection: []
            })
          }

          const { jobCreatingCompaniesList } = result.data
          const collection = jobCreatingCompaniesList?.collection || []
          const metadata = jobCreatingCompaniesList?.metadata || {}

          const cloneData = collection.map((item: IAgencyCompanies) => {
            return {
              value: item.id,
              supportingObj: {
                name: item.name
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  const promiseTalentPoolsOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryTalentPoolList, { ...params, status: 'active' })
        .toPromise()
        .then(
          (
            result: IResponseContextResult<{
              name: string
              id: number
            }>
          ) => {
            if (result.error) {
              catchErrorFromGraphQL({
                error: result.error
              })
              resolve({
                metadata: {
                  totalCount: configuration.defaultAsyncLoadingOptions
                },
                collection: []
              })
            }

            const { talentPoolsList } = result?.data
            const collection = talentPoolsList?.collection || []
            const metadata = talentPoolsList?.metadata || {}

            const cloneData = collection.map(
              (item: { name: string; id: number }) => {
                return {
                  value: String(item.id),
                  supportingObj: {
                    name: item.name
                  }
                }
              }
            )

            return resolve({ metadata, collection: cloneData })
          }
        )
    })

  const promiseTagsOptions = (params = {} as IPromiseSearchOption) =>
    new Promise<any>((resolve) => {
      clientGraphQL
        .query(QueryTagsList, {
          ...params,
          limit: 50,
          sortByAlphabet: 'true',
          kind: TAG_KIND.job
        })
        .toPromise()
        .then((result: IResponseContextResult<ITags>) => {
          if (result.error) {
            resolve({
              metadata: {
                totalCount: configuration.defaultPageSize
              },
              collection: []
            })
          }

          const { tagsList } = result?.data
          const collection = tagsList?.collection || []
          const metadata = tagsList?.metadata || {}

          const cloneData = collection.map((item: ITags) => {
            return {
              label: item.value,
              value: item.id,
              supportingObj: {
                name: item.name
              }
            }
          })

          return resolve({ metadata, collection: cloneData })
        })
    })

  return {
    promiseCompanyListOptions,
    promisePipelineTemplateOptions,
    promiseLocationOptions,
    promiseCompanyLocationOptions,
    promiseMembersOwnerOptions,
    promiseMembersOptions,
    promisePublicIndustriesOptions,
    promiseJobCategoriesOptions,
    promiseSkillsOptions,
    promiseTalentPoolsOptions,
    promiseTagsOptions,
    jobCurrency,
    jobEmployment,
    jobRemoteStatus,
    jobTypeOfSalary,
    jobLevel,
    jobEducation,
    languageList
  }
}

export default useJobs
