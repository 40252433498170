import { FC, useCallback, useEffect } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import EmailEditor from '~/components/SendMailFormControl/EmailEditor'
import { PUBLIC_APP_URL } from '~/core/constants/env'
import { AvatarGroup } from '~/core/ui/AvatarGroup'
import { Button } from '~/core/ui/Button'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import { TypographyText } from '~/core/ui/Text'
import { TextButton } from '~/core/ui/TextButton'
import { Toggle } from '~/core/ui/Toggle'
import { Tooltip } from '~/core/ui/Tooltip'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { defaultFormatDate } from '~/core/utilities/format-date'
import { useQueryCareerSetting } from '~/lib/features/calendar/hooks/use-query-career-setting'
import schemaScheduleInterviewStep2 from '~/lib/features/calendar/schema/validation-schedule-interview-step-2'
import {
  FirstStepFormType,
  InterviewDetailType,
  InterviewFormStep2,
  InterviewStep2EmailEditor
} from '~/lib/features/calendar/types'
import MutationUpdateEmailProfile from '~/lib/features/candidates/graphql/mutation-update-email-profile'
import { mappingDefaultValueStep2 } from '~/lib/features/interviews/mapping/schedule-interview-mapping'
import { useQueryEmailTemplateAttendees } from '~/lib/features/settings/email-templates/hooks/use-query-email-template-attendees'
import { IEmailTemplate } from '~/lib/features/settings/email-templates/types'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import useBoundStore from '~/lib/store'

const SecondStepForm: FC<{
  onPreviewStep: (data: InterviewStep2EmailEditor) => void
  onClose: () => void
  interviewInfo?: InterviewDetailType
  interviewSubmitData?: FirstStepFormType & InterviewFormStep2
  onScheduleInterview: (data: InterviewStep2EmailEditor) => Promise<void>
  reload?: () => void
  isSubmittingForm?: boolean
}> = ({
  onPreviewStep,
  onClose,
  interviewInfo,
  interviewSubmitData,
  onScheduleInterview,
  reload,
  isSubmittingForm
}) => {
  const { t } = useTranslation()
  const user = useBoundStore((state) => state.user)
  const setToast = useBoundStore((state) => state.setToast)
  const getEmailInterviewInfo = (interviewInfo?.profile?.email || []).length
  const { promiseEmailTemplatesAttendees } = useQueryEmailTemplateAttendees()

  // const ccList = useMemo(() => {
  //   const attendees = (interviewSubmitData?.attendees || [])?.map(
  //     (attendee) => ({
  //       value: attendee.supportingObj?.description || '',
  //       supportingObj: { name: attendee.supportingObj?.description || '' }
  //     })
  //   )
  //   return attendees.findIndex((attendee) => attendee.value === user.email) !==
  //     -1
  //     ? attendees
  //     : [
  //         {
  //           value: user.email || '',
  //           supportingObj: {
  //             name: user.email || ''
  //           }
  //         },
  //         ...attendees
  //       ]
  // }, [interviewSubmitData?.attendees, user.email])

  const {
    trigger: triggerUpdateEmailProfile,
    isLoading: isLoadingUpdateEmailProfile
  } = useSubmitCommon(MutationUpdateEmailProfile)

  const { data: careerSetting, trigger: triggerCareerSetting } =
    useQueryCareerSetting({ variables: {}, shouldPause: true })

  const onSubmitUpdateEmailProfile = useCallback(
    async (
      params: { email: string },
      callbackUpdateToEmail?: (email: string) => void
    ) => {
      if (isLoadingUpdateEmailProfile) {
        return
      }

      callbackUpdateToEmail && callbackUpdateToEmail(params.email)

      triggerUpdateEmailProfile({
        id: Number(interviewInfo?.profile?.id),
        email: [params.email]
      }).then((result) => {
        if (result.error) {
          return catchErrorFromGraphQL({
            error: result.error,
            setToast
          })
        }

        return reload && reload()
      })
    },
    [
      interviewInfo?.profile?.id,
      isLoadingUpdateEmailProfile,
      reload,
      setToast,
      triggerUpdateEmailProfile
    ]
  )

  useEffect(() => {
    triggerCareerSetting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DynamicImportForm
      isShowDebug={false}
      className="w-full"
      schema={schemaScheduleInterviewStep2(t)}
      defaultValue={mappingDefaultValueStep2({
        interviewInfo,
        interviewSubmitData,
        getEmailInterviewInfo,
        currentUser: user
      })}
      onSubmit={onScheduleInterview}>
      {({ formState, control, setValue, clearErrors, setError, getValues }) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const sendEmailToCandidateWatcher = useWatch({
          control,
          name: 'sendEmailToCandidate'
        })
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const sendEmailToAttendeesWatcher = useWatch({
          control,
          name: 'sendEmailToAttendees'
        })

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const toEmailWatcher = useWatch({
          control,
          name: 'to'
        })

        const callBackAfterSubmitEmail = (email: string) => {
          setValue('to', [
            {
              value: email,
              supportingObj: {
                name: email
              }
            }
          ])
        }

        return (
          <>
            <div className="mb-5 rounded-md bg-gray-50 px-3 py-2.5">
              <div className="mb-1.5 flex justify-between">
                <TypographyText className="text-sm font-medium text-gray-900">
                  {interviewSubmitData?.eventType?.supportingObj?.name}
                </TypographyText>

                <div>
                  <AvatarGroup
                    size="xs"
                    source={interviewSubmitData?.attendees?.map(
                      (item, index) => ({
                        id: Number(item.value || index),
                        alt: item.supportingObj?.name,
                        defaultColour: item.supportingObj?.defaultColour,
                        src: item?.avatar || item?.avatarVariants?.thumb?.url,
                        tooltip: <div>{item.supportingObj?.name} </div>
                      })
                    )}
                  />
                </div>
              </div>
              <div className="mb-1.5 flex items-center">
                <div className="mr-2">
                  <IconWrapper
                    name="Calendar"
                    size={14}
                    className="text-gray-400"
                  />
                </div>
                <div className="flex items-center text-xs text-gray-600">
                  <TypographyText>
                    {interviewSubmitData?.date &&
                      defaultFormatDate(new Date(interviewSubmitData.date))}
                  </TypographyText>
                  <div className="mx-1.5 h-0.5 w-0.5 rounded bg-gray-400" />

                  <TypographyText>
                    {`${interviewSubmitData?.startTime?.value} - ${interviewSubmitData?.endTime?.value}`}
                  </TypographyText>
                </div>
              </div>
              {interviewInfo?.applicant?.job?.title && (
                <div className="mb-1.5 flex items-center">
                  <div className="mr-2">
                    <IconWrapper
                      name="Briefcase"
                      size={14}
                      className="text-gray-400"
                    />
                  </div>
                  <div className="text-xs text-gray-600">
                    {interviewInfo?.applicant?.job?.title}
                  </div>
                </div>
              )}
              {interviewSubmitData?.locationId?.supportingObj?.name && (
                <div className="mb-1.5 flex items-center">
                  <div className="mr-2">
                    <IconWrapper
                      name="MapPin"
                      size={14}
                      className="text-gray-400"
                    />
                  </div>
                  <div className="text-xs text-gray-600">
                    {interviewSubmitData?.locationId?.supportingObj?.name}
                  </div>
                </div>
              )}
              {interviewSubmitData?.meetingUrl && (
                <div className="flex items-center">
                  <div className="mr-2">
                    <IconWrapper
                      name="Link"
                      size={14}
                      className="text-gray-400"
                    />
                  </div>
                  <Tooltip content={interviewSubmitData?.meetingUrl}>
                    <div className="line-clamp-1 block max-w-[550px] overflow-hidden text-ellipsis whitespace-nowrap text-xs text-gray-600">
                      {interviewSubmitData?.meetingUrl}
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
            <TypographyText className="mb-3 text-sm font-medium text-gray-900">
              {t('interview:schedule_modal:emailNotification')}
            </TypographyText>
            <div className="mb-5 grid grid-cols-2 space-x-5">
              <div className="">
                <Controller
                  control={control}
                  name="sendEmailToCandidate"
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlItem
                        destructive={
                          formState.errors &&
                          !!formState.errors.sendEmailToCandidate
                        }
                        destructiveText={
                          formState.errors &&
                          (formState.errors.sendEmailToCandidate
                            ?.message as string)
                        }>
                        <div className="flex space-x-2">
                          <Toggle
                            isChecked={value}
                            name="view-switch"
                            onCheckedChange={(checked) => {
                              onChange(checked)
                            }}
                            size="sm"
                            toggle="trailing"
                          />
                          <div className="flex-1">
                            <div className="mb-1 text-sm font-medium text-gray-900">
                              {t('interview:schedule_modal:notifyCandidate')}
                            </div>
                            <div className="flex items-center space-x-1 text-sm text-gray-500">
                              <span className="flex-none">
                                {`${t(
                                  'interview:schedule_modal:sendEventDetails'
                                )} `}
                              </span>
                              <Tooltip
                                content={interviewInfo?.profile?.fullName}>
                                <span className="line-clamp-1 font-medium text-gray-600">
                                  {interviewInfo?.profile?.fullName}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </FormControlItem>
                    )
                  }}
                />
              </div>
              <div className="">
                <Controller
                  control={control}
                  name="sendEmailToAttendees"
                  defaultValue={false}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlItem
                        destructive={
                          formState.errors &&
                          !!formState.errors.sendEmailToAttendees
                        }
                        destructiveText={
                          formState.errors &&
                          (formState.errors.sendEmailToAttendees
                            ?.message as string)
                        }>
                        <div className="flex space-x-2">
                          <Toggle
                            isChecked={value}
                            name="view-switch"
                            onCheckedChange={(checked) => {
                              onChange(checked)
                            }}
                            size="sm"
                            toggle="trailing"
                          />
                          <div className="flex-1">
                            <div className="mb-1 text-sm font-medium text-gray-900">
                              {t('interview:schedule_modal:notifyAttendees')}
                            </div>
                            <div className="flex items-center space-x-1 text-sm text-gray-500">
                              <span className="flex-none">
                                {`${t(
                                  'interview:schedule_modal:sendInterviewDetails'
                                )} `}
                              </span>
                              <Tooltip
                                content={
                                  <>
                                    {interviewSubmitData?.attendees?.map(
                                      (attendee) => (
                                        <div key={`attendee-${attendee.value}`}>
                                          {attendee.supportingObj?.name}{' '}
                                          {attendee?.supportingObj?.description
                                            ? `(${attendee?.supportingObj?.description})`
                                            : ''}
                                        </div>
                                      )
                                    )}
                                  </>
                                }>
                                <span className="line-clamp-1 font-medium text-gray-600">
                                  {t(
                                    'interview:schedule_modal:countAttendees',
                                    {
                                      count:
                                        interviewSubmitData?.attendees?.length
                                    }
                                  )}
                                </span>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </FormControlItem>
                    )
                  }}
                />
              </div>
            </div>
            {!!sendEmailToCandidateWatcher && !!careerSetting && (
              <div className="mb-5">
                <EmailEditor
                  label={`${t('label:emailToCandidate')}`}
                  control={control}
                  setValue={setValue}
                  setError={setError}
                  formState={formState}
                  clearErrors={clearErrors}
                  defaultShowCc={true}
                  showFields={['to', 'subject', 'emailTemplate', 'htmlBody']}
                  disabledFields={['to']}
                  templateType={['event']}
                  fieldsName={{
                    emailTemplate: 'emailTemplate',
                    // jobId: 'jobId',
                    htmlBody: 'htmlBodyCandidate',
                    subject: 'subjectCandidate',
                    to: 'to'
                    // cc: 'ccAttendees',
                    // bcc: 'bccAttendees'
                  }}
                  placeholderValue={{
                    event_preview_link:
                      '<div style="margin-top: 50px; margin-bottom: 50px;"><div style="text-align: center;"><a href="%{event_preview_link}" class="button button--" target="_blank">View Event</a></div></div>',
                    candidate_fullname: interviewInfo?.profile?.fullName || '',
                    candidate_email: interviewInfo?.profile?.email
                      ? Array.isArray(interviewInfo?.profile?.email)
                        ? interviewInfo?.profile?.email?.[0]
                        : interviewInfo?.profile?.email
                      : toEmailWatcher?.[0]?.supportingObj?.name || '',
                    recruiter_email:
                      interviewInfo?.applicant?.job?.owner?.email || '',
                    recruiter_fullname:
                      interviewInfo?.applicant?.job?.owner?.fullName || '',
                    job_title: interviewInfo?.applicant?.job?.title || '',
                    job_link: interviewInfo?.applicant?.job?.slug
                      ? `<a href="${PUBLIC_APP_URL}${
                          careerSetting?.canonical_url
                        }${interviewInfo?.applicant?.job?.slug}">${t(
                          'interview:schedule_modal:viewJobLink'
                        )}</a>`
                      : '',
                    company_name: user?.currentTenant?.name || '',
                    career_page: `<a href="${PUBLIC_APP_URL}${careerSetting?.canonical_url}">${user?.currentTenant?.name}</a>`,
                    event_full: `${t('interview:schedule_modal:eventFull', {
                      eventTypeName:
                        interviewSubmitData?.eventType?.supportingObj?.name,
                      date: interviewSubmitData?.date
                        ? defaultFormatDate(interviewSubmitData.date)
                        : '',
                      startTime: interviewSubmitData?.startTime?.value,
                      endTime: interviewSubmitData?.endTime?.value,
                      timezoneName:
                        interviewSubmitData?.timezone?.supportingObj?.name,
                      attendees: interviewSubmitData?.attendees
                        ?.map((attendee) => attendee?.supportingObj?.name)
                        ?.join('/')
                    })}`,
                    event_attendees:
                      interviewSubmitData?.attendees
                        ?.map((attendee) => attendee?.supportingObj?.name)
                        ?.join('/') || '',
                    event_date_time: interviewSubmitData?.date
                      ? `${defaultFormatDate(
                          new Date(interviewSubmitData.date)
                        )} ${interviewSubmitData?.startTime?.value}-${
                          interviewSubmitData?.endTime?.value
                        } ${interviewSubmitData?.timezone?.supportingObj?.name}`
                      : '',
                    event_location:
                      interviewSubmitData?.locationId?.supportingObj?.name ||
                      '',
                    event_type:
                      interviewSubmitData?.eventType?.supportingObj?.name || ''
                  }}
                  defaultTemplateEmail={
                    interviewInfo?.emailTemplate
                      ? (interviewInfo?.emailTemplate as IEmailTemplate)
                      : undefined
                  }
                  isShowComposeEmail={
                    !!getEmailInterviewInfo ||
                    (toEmailWatcher || [])?.length > 0
                  }
                  onSubmitUpdateEmailProfile={(data) =>
                    onSubmitUpdateEmailProfile(data, callBackAfterSubmitEmail)
                  }
                  isLoadingUpdateEmailProfile={isLoadingUpdateEmailProfile}
                />
              </div>
            )}
            {!!sendEmailToAttendeesWatcher && !!careerSetting && (
              <div className="mb-5">
                <EmailEditor
                  label={`${t('label:emailToAttendees')}`}
                  control={control}
                  setValue={setValue}
                  setError={setError}
                  formState={formState}
                  clearErrors={clearErrors}
                  // defaultShowCc={true}
                  showFields={['to', 'subject', 'cc', 'bcc', 'htmlBody']}
                  disabledFields={['to']}
                  templateType={['event']}
                  fieldsName={{
                    emailTemplate: 'emailTemplateAttendee',
                    // jobId: 'jobId',
                    htmlBody: 'htmlBodyAttendees',
                    subject: 'subjectAttendees',
                    to: 'toAttendees',
                    cc: 'ccAttendees',
                    bcc: 'bccAttendees'
                  }}
                  placeholderValue={{
                    event_preview_link:
                      '<div style="margin-top: 50px; margin-bottom: 50px;"><div style="text-align: center;"><a href="%{event_preview_link}" class="button button--" target="_blank">View Event</a></div></div>',
                    candidate_fullname: interviewInfo?.profile?.fullName || '',
                    candidate_email: interviewInfo?.profile?.email
                      ? Array.isArray(interviewInfo?.profile?.email)
                        ? interviewInfo?.profile?.email?.[0]
                        : interviewInfo?.profile?.email
                      : toEmailWatcher?.[0]?.supportingObj?.name || '',
                    recruiter_email:
                      interviewInfo?.applicant?.job?.owner?.email || '',
                    recruiter_fullname:
                      interviewInfo?.applicant?.job?.owner?.fullName || '',
                    job_title: interviewInfo?.applicant?.job?.title || '',
                    job_link: interviewInfo?.applicant?.job?.slug
                      ? `<a href="${PUBLIC_APP_URL}${
                          careerSetting?.canonical_url
                        }${interviewInfo?.applicant?.job?.slug}">${t(
                          'interview:schedule_modal:viewJobDetail'
                        )}</a>`
                      : '',
                    company_name: user?.currentTenant?.name || '',
                    career_page: `<a href="${PUBLIC_APP_URL}${careerSetting?.canonical_url}">${user?.currentTenant?.name}</a>`,
                    event_full: `${t('interview:schedule_modal:eventFull', {
                      eventTypeName:
                        interviewSubmitData?.eventType?.supportingObj?.name,
                      date: interviewSubmitData?.date
                        ? defaultFormatDate(interviewSubmitData.date)
                        : '',
                      startTime: interviewSubmitData?.startTime?.value,
                      endTime: interviewSubmitData?.endTime?.value,
                      timezoneName:
                        interviewSubmitData?.timezone?.supportingObj?.name,
                      attendees: interviewSubmitData?.attendees
                        ?.map((attendee) => attendee?.supportingObj?.name)
                        ?.join('/')
                    })}`,
                    event_attendees:
                      interviewSubmitData?.attendees
                        ?.map((attendee) => attendee?.supportingObj?.name)
                        ?.join('/') || '',
                    event_date_time: interviewSubmitData?.date
                      ? `${defaultFormatDate(
                          new Date(interviewSubmitData.date)
                        )} ${interviewSubmitData?.startTime?.value}-${
                          interviewSubmitData?.endTime?.value
                        } ${interviewSubmitData?.timezone?.supportingObj?.name}`
                      : '',
                    event_location:
                      interviewSubmitData?.locationId?.supportingObj?.name ||
                      '',
                    event_type:
                      interviewSubmitData?.eventType?.supportingObj?.name || ''
                  }}
                  defaultTemplateEmail={
                    interviewInfo?.emailTemplate
                      ? (interviewInfo?.emailTemplate as IEmailTemplate)
                      : undefined
                  }
                  isShowComposeEmail={true}
                  promiseEmailTemplates={promiseEmailTemplatesAttendees}
                  onSubmitUpdateEmailProfile={(data) => Promise.resolve()}
                  isLoadingUpdateEmailProfile={isLoadingUpdateEmailProfile}
                />
              </div>
            )}

            <div className="-mx-6 flex justify-between border-t border-t-gray-100 px-6 pt-6">
              <TextButton
                size="md"
                type="secondary"
                label={`${t('button:back')}`}
                iconMenus="ChevronLeft"
                icon="leading"
                onClick={() => onPreviewStep(getValues())}
              />
              <div className="flex">
                <Button
                  className="mr-3"
                  type="secondary"
                  size="sm"
                  label={`${t('button:cancel')}`}
                  onClick={onClose}
                />
                <Button
                  label={`${t('button:schedule')}`}
                  size="sm"
                  htmlType="submit"
                  isLoading={isSubmittingForm}
                  isDisabled={
                    (sendEmailToCandidateWatcher &&
                      (toEmailWatcher || [])?.length === 0) ||
                    isSubmittingForm
                  }
                />
              </div>
            </div>
          </>
        )
      }}
    </DynamicImportForm>
  )
}

export default SecondStepForm
